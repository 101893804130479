<template>
  <div v-if="contact" class="grid-x grid-padding-x">
    <div class="cell large-6 medium-12 small-12">
      <md-field :class="getValidationClass('email')">
        <label :for="getContactEmailId">Email<span v-if="insurance"> (Versicherung)</span></label>
        <md-input type="email" :name="getContactEmailId" :id="getContactEmailId" autocomplete="off"
                  v-model="contact.email" :disabled="sending || emailDisabled"/>
        <span class="md-error"
              v-if="!$v.contact.email.required">Email-Adresse ist notwendig</span>
        <span class="md-error"
              v-else-if="!$v.contact.email.email">Ungültige Email-Adresse</span>
      </md-field>
    </div>
    <div v-if="!insurance" class="cell large-6 medium-6 small-12">
      <md-field :class="getValidationClass('mobilePhone')">
        <label :for="getMobilePhoneId">Telefonnummer (Mobil)</label>
        <md-input :name="getMobilePhoneId" :id="getMobilePhoneId" autocomplete="off"
                  v-model="contact.mobilePhone" :disabled="sending"/>
      </md-field>
    </div>
    <div v-if="!insurance" class="cell large-6 medium-6 small-12">
      <md-field :class="getValidationClass('phone')">
        <label :for="getPhoneId">Telefonnummer (Zuhause)</label>
        <md-input :name="getPhoneId" :id="getPhoneId" autocomplete="off"
                  v-model="contact.phone" :disabled="sending"/>
      </md-field>
    </div>
    <div class="cell large-6 medium-6 small-12">
      <md-field :class="getValidationClass('businessPhone')">
        <label :for="getBusinessPhoneId"><span v-if="insurance">Telefonnummer (Versicherung)</span><span v-else>Telefonnummer (Arbeit)</span></label>
        <md-input :name="getBusinessPhoneId" :id="getBusinessPhoneId" autocomplete="off"
                  v-model="contact.businessPhone" :disabled="sending"/>
      </md-field>
    </div>
    <div v-if="!insurance" class="cell large-6 medium-6 small-12">
      <md-field :class="getValidationClass('fax')">
        <label :for="getFaxId">Faxnummer</label>
        <md-input :name="getFaxId" :id="getFaxId" autocomplete="off"
                  v-model="contact.fax" :disabled="sending"/>
      </md-field>
    </div>
  </div>
</template>

<script>

  import {validationMixin} from 'vuelidate'
  import {
    email,
  } from 'vuelidate/lib/validators'

  export default {
    name: 'ContactForm',
    mixins: [validationMixin],

    props: {
      contact: {
        required: true
      },
      disableEmail: {
        required: false
      },
      insurance: {
        required: false
      },
      sending: {
        required: true
      },
    },

    mounted() {
      if (this.disableEmail) {
        this.emailDisabled = this.disableEmail;
      }
    },

    data: () => ({
      invalid: true,
      rand: Math.floor(Math.random() * Math.floor(999999)),
      emailDisabled: false,
    }),

    validations: {
      contact: {
        email: {
          email,
        },
        mobilePhone: {},
        phone: {},
        businessPhone: {},
        fax: {},
      },
    },

    methods: {
      getValidationClass(fieldName) {
        let field = this.$v.contact[fieldName];

        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      validateContact() {
        this.$v.$touch();
        this.invalid = this.$v.$invalid;
      },
    },

    computed: {

      getContactEmailId() {
        return 'contactEmail_' + this.rand;
      },

      getMobilePhoneId() {
        return 'mobilePhone_' + this.rand;
      },

      getPhoneId() {
        return 'phone_' + this.rand;
      },

      getBusinessPhoneId() {
        return 'businessPhone_' + this.rand;
      },

      getFaxId() {
        return 'fax_' + this.rand;
      },

      organization() {
        return this.$store.getters.organization
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>