<template>
  <div v-if="person" class="grid-x grid-padding-x">

    <div v-if="!salutationDisabled" class="cell large-6 medium-6 small-6">
      <md-field :class="getValidationClass('salutation')">
        <label>Anrede</label>
        <md-select v-model="person.salutation" autocomplete="off" :disabled="sending">
          <md-option v-for="salutation of labels.salutations" v-bind:value="salutation" :key="salutation">
            {{labels.salutation[salutation]}}
          </md-option>
        </md-select>
        <span class="md-error" v-if="!$v.person.salutation.required">Anrede ist notwendig</span>
      </md-field>
    </div>

    <div v-if="!titleDisabled" class="cell large-6 medium-6 small-6">
      <md-field :class="getValidationClass('title')">
        <label :for="getTitleId">Titel</label>
        <md-input :name="getTitleId" :id="getTitleId" autocomplete="off" v-model="person.title" :disabled="sending"/>
      </md-field>
    </div>
    <div class="cell large-6 medium-6 small-6">
      <md-field :class="getValidationClass('firstname')">
        <label :for="getFirstNameId">Vorname</label>
        <md-input :name="getFirstNameId" :id="getFirstNameId" autocomplete="off" v-model="person.firstname" :disabled="sending"/>
        <span class="md-error" v-if="!$v.person.firstname.required">Vorname ist notwendig</span>
      </md-field>
    </div>
    <div class="cell large-6 medium-6 small-6">
      <md-field :class="getValidationClass('lastname')">
        <label :for="getLastNameId">Nachname</label>
        <md-input :name="getLastNameId" :id="getLastNameId" autocomplete="off" v-model="person.lastname" :disabled="sending"/>
        <span class="md-error" v-if="!$v.person.lastname.required">Nachname ist notwendig</span>
      </md-field>
    </div>
    <div v-if="sexEnabled" class="cell large-6 medium-6 small-6">
      <md-field :class="getValidationClass('sex')">
        <label>Geschlecht</label>
        <md-select v-model="person.sex" autocomplete="off" :disabled="sending">
          <md-option v-for="sex of labels.sexs" v-bind:value="sex" :key="sex">
            {{labels.sex[sex]}}
          </md-option>
        </md-select>
        <span class="md-error" v-if="!$v.person.salutation.required">Anrede ist notwendig</span>
      </md-field>
    </div>
    <div v-if="!birthDateDisabled" class="cell large-6 medium-6 small-6">
      <div class="md-layout">
        <div v-if="validBirthdate && !birthDateDisabled" class="md-layout-item">
          <md-datepicker id="birth-date-dp" :md-debounce="10000" :md-open-on-focus="false" v-model="birthdate" md-immediately :disabled="sending">
            <label>Geburtstag</label>
          </md-datepicker>
        </div>
      </div>
    </div>
    <div v-if="editMore" class="cell large-6 medium-6 small-6">
      <md-field :class="getValidationClass('profession')">
        <label :for="getProfessionId">Beruf</label>
        <md-input :name="getProfessionId" :id="getProfessionId" autocomplete="off" v-model="person.profession" :disabled="sending"/>
      </md-field>
    </div>
    <div v-if="editMore" class="cell large-6 medium-6 small-6">
      <label class="small-label">Darf Kind abhohlen</label>
      <md-switch class="md-primary" v-model="person.pickUpEntitled">
        <p :class="booleanText(person.pickUpEntitled)">{{booleanText(person.pickUpEntitled)}}</p>
      </md-switch>
    </div>
    <div v-if="editMore" class="cell large-6 medium-6 small-6">
      <label class="small-label">Sorgerecht</label>
      <md-switch class="md-primary" v-model="person.custody">
        <p v-if="person.custody">ja</p>
        <p v-else>nein</p>
      </md-switch>
    </div>
    <div v-if="editMore" class="cell large-6 medium-6 small-6">
      <label class="small-label">alleiniges Sorgerecht</label>
      <md-switch class="md-primary" v-model="person.soleCustody">
        <p v-if="person.soleCustody">ja</p>
        <p v-else>nein</p>
      </md-switch>
    </div>
    <div v-if="editMore" class="cell large-6 medium-6 small-6">
      <label class="small-label">alleinerziehend</label>
      <md-switch class="md-primary" v-model="person.singleParent">
        <p v-if="person.singleParent">ja</p>
        <p v-else>nein</p>
      </md-switch>
    </div>
    <div v-if="!gdprDisabled" class="cell large-6 medium-6 small-6">
      <label :class="checkBoxWarningClass(!person.dsgvoConfirmationObtained)">Einwilligung Datenverarbeitung
      </label>
      <md-switch class="md-primary" v-model="person.dsgvoConfirmationObtained">
        <p :class="booleanText(person.dsgvoConfirmationObtained)">{{booleanText(person.dsgvoConfirmationObtained)}}</p>
      </md-switch>
      <md-tooltip>
        <div v-if="!person.dsgvoConfirmationObtained">
          Die Einwilligung gemäß Datenschutz-Grundverordnung (Art. 6 DSGVO)<br>
          zur Verarbeitung dieser personenbezogenen Daten muss<br>
          seitens <b v-if="person.fullName">{{person.fullName}}</b>
          <b v-else-if="sexEnabled">eines Erziehungsberechtigten</b>
          <b v-else>dieser Person</b> schriftlich gegeben sein.
        </div>
      </md-tooltip>
    </div>
  </div>
</template>

<script>

  import jQuery from 'jquery';
  import {validationMixin} from 'vuelidate';
  import Vue from 'vue';

  import {
    required,
  } from 'vuelidate/lib/validators';

  export default {
    name: 'PersonForm',
    mixins: [validationMixin],

    props: {
      person: {
        required: true
      },
      sending: {
        required: true
      },
      editMore: {
        required: false
      },
      sexEnabled: {
        required: false
      },
      salutationDisabled: {
        required: false
      },
      titleDisabled: {
        required: false
      },
      birthDateDisabled: {
        required: false
      },
      gdprDisabled: {
        required: false
      },
    },

    created() {
      this.formatBirthdate();
      this.initDatePickerSyncHandler(true);
    },

    updated() {
      this.writeBackDates();
    },

    beforeDestroy() {
      this.initDatePickerSyncHandler(false);
    },

    watch: {
      person() {
        this.birthdate = null;
        this.validBirthdate = false;
        this.formatBirthdate();
      },
    },

    data: () => ({
      invalid: true,
      birthdate: null,
      validBirthdate: false,
      rand: Math.floor(Math.random() * Math.floor(999999)),
    }),

    validations: {
      person: {
        salutation: {},
        title: {},
        sex: {},
        firstname: {
          required,
        },
        lastname: {
          required,
        },
      },
    },

    methods: {

      initDatePickerSyncHandler(init) {
        if (init) {
          setTimeout(() => {
            jQuery('#birth-date-dp').children('input').bind('blur', () => {
              this.birthdate = jQuery('#birth-date-dp').children('input').val();
              this.writeBackDates();
            })
          }, 500);
        } else {
          jQuery('#birth-date-dp').children('input').unbind('blur');
        }
      },

      booleanText(value) {
        return value ? 'ja' : 'nein';
      },

      formatBirthdate() {
        if (this.birthdate === null && this.person) {
          if (this.person.birthdate) {
            this.birthdate = Vue.moment(this.person.birthdate).format('DD.MM.YYYY');
          } else {
            this.birthdate = null;
          }
          this.validBirthdate = true;
        }
      },

      getValidationClass(fieldName) {
        let field = this.$v.person[fieldName];

        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      writeBackDates() {
        setTimeout(() => {
          if (this.person) {
            if (this.birthdate) {
              this.person.birthdate = Vue.moment(this.birthdate, 'DD.MM.YYYY').format('YYYY-MM-DD');
            } else {
              this.person.birthdate = null;
            }
          }

          this.$emit("updateBirthDate", this.person.birthdate);
        }, 100);
      },

      validatePerson() {
        this.writeBackDates();
        this.$v.$touch();
        this.invalid = this.$v.$invalid;
      },

      checkBoxWarningClass(warn) {
        return warn ? 'small-label cb-warn' : 'small-label';
      },
    },

    computed: {

      getTitleId() {
        return 'title_' + this.rand;
      },

      getSalutationId() {
        return 'salutation_' + this.rand;
      },

      getFirstNameId() {
        return 'firstName:' + this.rand;
      },

      getLastNameId() {
        return 'lastName:' + this.rand;
      },

      getProfessionId() {
        return 'profession:' + this.rand;
      },

      labels() {
        return this.$store.getters.labels
      },
    },
  };
</script>

<style lang="scss" scoped>

  .ja {
    color: forestgreen;
    font-weight: bold;
    font-size: larger;
  }

  .nein {
    color: red;
    font-weight: bold;
    font-size: larger;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    white-space: normal;
  }

  .cb-warn {
    color: red;
    font-weight: bold;
  }
</style>